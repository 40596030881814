import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const originPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originPush.call(this, location).catch(err => err)
}

const originReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('@/game/index'),
      meta: { title: 'home', keepAlive: true, icon: 'el-icon-truck' }
    },
    {
      path: '/index',
      name: 'index',
      component: () => import('@/game/index'),
      meta: { title: 'home', keepAlive: true, icon: 'el-icon-truck' }
    },
    {
      path: '/search',
      name: 'search',
      component: () => import('@/game/search'),
      meta: { title: 'search', keepAlive: true, icon: 'el-icon-truck' }
    },
    {
      path: '/details',
      name: 'details',
      component: () => import('@/game/details'),
      meta: { title: 'details', keepAlive: true, icon: 'el-icon-truck' }
    },
    {
      path: '/game',
      name: 'game',
      component: () => import('@/game/game'),
      meta: { title: 'game', keepAlive: true, icon: 'el-icon-truck' }
    },
    {
      path: '/list',
      name: 'list',
      component: () => import('@/game/list'),
      meta: { title: 'list', keepAlive: true, icon: 'el-icon-truck' }
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('@/user/privacy'),
      meta: { title: 'list', keepAlive: true, icon: 'el-icon-truck' }
    },
    {
      path: '/service',
      name: 'service',
      component: () => import('@/user/service'),
      meta: { title: 'list', keepAlive: true, icon: 'el-icon-truck' }
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/user/about'),
      meta: { title: 'list', keepAlive: true, icon: 'el-icon-truck' }
    }
  ]
})
export default router
