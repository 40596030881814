<template>
  <div id="app">

    <router-view class="router-view" />
  </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
export default {
  name: 'App',
  mounted () {
    axios.get('/data.json').then(response => {
      // 处理获取到的数据
      Vue.prototype.$globalConstant = response.data;
    })
    .catch(error => {
      console.error(error);
    });
  }

}
</script>
<style>
html,body,#app {
  margin: 0 !important;
  width: 100%;
  height: 100%;
}

</style>
