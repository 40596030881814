import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueCookies from 'vue-cookies'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css' // 确保引入正确的CSS文件路径
// import dataJSON from './assets/static.json'
Vue.use(VueCookies)
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI)
// Vue.use(VueAwesomeSwiper, /* { default global options } */)
Vue.config.ignoredElements = [/^qa-router-button|templates|styles/]
// Vue.prototype.$staticData = dataJSON
new Vue({
  render: h => h(App),
  setup () {
    return {

    }
  },
  router
}).$mount('#app')
